import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// IE版本
const v = IEVersion()
Vue.prototype.$lowie = (v > 0 && v < 10) ? true : false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

function IEVersion () {
  var userAgent = navigator.userAgent 
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1  
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1
  if(isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);")
    reIE.test(userAgent)
    var fIEVersion = parseFloat(RegExp["$1"])
    return fIEVersion
  }
  if(isEdge) return 'edge'
  if(isIE11) return 11         // IE11  
  return -1                    // 不是ie浏览器
}