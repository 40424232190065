<template>
  <div id="app">
    <my-header></my-header>
    <transition name="fade" mode="in-out">
      <!-- <keep-alive> -->
        <router-view/>
      <!-- </keep-alive> -->
    </transition>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { myHeader, myFooter } from '@/components'
export default {
  name: 'App',
  components: {
    myHeader,
    myFooter
  },
  data(){
    return {

    }
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/_variables.scss';
  @import 'assets/scss/_extend.scss';
  *{
      box-sizing: border-box;
      outline: 0;
      padding:0;
      margin:0;
      -webkit-tap-highlight-color:transparent;
  }
  ul,li,ol,dl,dt,dd{
      list-style: none;
  }
  i,em,var{
      font-style:normal;
  }
  a{
      text-decoration: none;
      color: $color-second;
      img{
          border: none;
          outline: none;
      }
      // &.link,&:hover{
      //     color: $color-main;
      // }
  }
  table{
      border-collapse:collapse;
  }
  html,body{
    height: 100%;
  }
  #app{
    min-height: 100%;
  }
  body{
    font: 12px/1.5 'Microsoft Yahei','Helvetica Neue',Helvetica,sans-serif;
    color: $color-primary;
    background: #fff;
  }
  img,a,input{
    &,&:focus{
      outline: none;
    }
  }
  @media screen and (max-width:1200px) {
    body{
      padding-top: px2rem(44px);
    }
  }
</style>
