const prod = [
  {
    name: '权益任务活动平台',
    intro: '优化活动开展，提升活动效能',
    icon: require('@/assets/images/home/p-03.png')
  },
  {
    name: '商城平台',
    intro: '支持丰富的电商类场景',
    icon: require('@/assets/images/home/p-04.png')
  },
  {
    name: '营销活动工具平台',
    double: true,
    intro: '快速实现电子渠道集成、线下网点活动投放',
    icon: require('@/assets/images/home/p-05.png')
  },
  {
    name: '客户标签系统',
    intro: '更好地实现数据价值',
    icon: require('@/assets/images/home/p-07.png')
  },
  {
    name: '客户成长体系',
    intro: '围绕客户转化的成长体系设计',
    icon: require('@/assets/images/home/p-15.png')
  },
  {
    name: '绿色激励',
    intro: '碳普惠开放平台,推动绿色低碳生活方式',
    icon: require('@/assets/images/home/p-16.png')
  },
  {
    name: '积分权益核心',
    intro: '全业务、全介质，统一管理，统一风控',
    icon: require('@/assets/images/home/p-06.png')
  },
  {
    name: '权益中心',
    double: true,
    intro: '集中管理全行商品和权益资源，开放入驻统一供应',
    icon: require('@/assets/images/home/p-08.png')
  },
  {
    name: '客户数智化指标分析',
    intro: '客户数智化经营指标看板分析',
    icon: require('@/assets/images/home/p-17.png')
  },
  {
    name: '客户经理小程序',
    intro: '为客户经理营销赋能',
    icon: require('@/assets/images/home/p-01.png')
  },
  {
    name: '金融生活APP',
    intro: '增强银行零售客户的粘性',
    icon: require('@/assets/images/home/p-11.png')
  },
  {
    name: '合伙人平台',
    intro: '通过社交裂变实现快速传播导购',
    icon: require('@/assets/images/home/p-02.png')
  },
  {
    name: '缴费通平台',
    intro: '沉淀客户数据',
    icon: require('@/assets/images/home/p-10.png')
  },
  
  {
    name: '聚合支付平台',
    intro: '支付连接金融',
    icon: require('@/assets/images/home/p-09.png')
  },

 
  {
    name: '慧言吐槽',
    intro: '帮助企业改善产品和服务',
    icon: require('@/assets/images/home/p-12.png')
  }
]
  
export default prod