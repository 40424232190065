<template>
  <ul class="items-list">
    <li v-for="(item,i) in items" :key="i">
      <img :src="item.icon" />
      <dl>
        <dt>{{ item.name }}</dt>
        <dd>{{ item.intro }}</dd>
      </dl>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'itemsList',
  props: {
    items: {
      type: Array,
      require: true,
      default: ()=>{
        return []
      }
    }
  },
  data(){
    return {
      // codeShow: false
    }
  },
  computed: {
    
  },
  mounted() {
    // let t = this
    // window.onscroll = function(){
    //   if((window.scrollY || window.pageYOffset) > 400) t.codeShow = true
    //   else t.codeShow = false
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .items-list{
    @extend %c;
    width: 1201px;
    margin: 0 auto 30px;
    li{
      float: left;
      width: 280px;
      height: 280px;
      cursor: pointer;
      // @extend %transition;
      // box-shadow: 0 0 15px rgba(255,255,255,0);
      box-shadow: 0 0 15px rgba(0,0,0,0.04);
      border-radius: 10px;
      padding-top: 20px;
      &+li{
        margin-left: 27px;
      }
      &:hover{
        // box-shadow: 0 0 15px rgba(0,0,0,0.04);
        img{
          width: 240px
        }
        dd{
          height: 16px;
          opacity: 1;
          color: $color-primary * 3;
        }
      }
    }
    img{
      @extend %transition;
      display: block;
      width: 260px;
      margin: 0 auto 20px;
    }
    dt{
      font-size: 18px;
      line-height: 1;
      text-align: center;
      margin-bottom: 20px;
    }
    dd{
      @extend %transition;
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 16px;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }

  @media screen and (max-width:1200px) {
    .items-list{
      width: 100%;
      margin: 0 auto px2rem(15px);
      padding: 0 4%;
      li{
        float: left;
        width: 47.8%;
        height: px2rem(200px);
        box-shadow: 0 0 px2rem(7.5px) rgba(0,0,0,0.04);
        border-radius: px2rem(5px);
        padding: px2rem(20px) 0 0;
        margin: 0 0 px2rem(15px);
        &:nth-of-type(odd){
          margin-right: 4.4%;
        }
        &+li{
          margin-left: 0;
        }
        &:hover{
          img{
            width: 86%;
          }
          dd{
            height: auto;
            opacity: 1;
            color: $color-primary * 3;
          }
        }
      }
      img{
        display: block;
        width: 86%;
        margin: 0 auto px2rem(10px);
      }
      dt{
        font-size: px2rem(14px);
        margin-bottom: px2rem(10px);
      }
      dd{
        font-size: px2rem(12px);
        line-height: px2rem(16px);
        color: $color-primary * 3;
        height: auto;
        opacity: 1;
        padding: 0 px2rem(15px);
      }
    }
  }
</style>
