import Vue from 'vue'

let state = Vue.observable({
  aboutTab: 0,
  joinTab: 0,
  contactTab: 0
});

let mutations = {
  setAboutTab(index){
    state.aboutTab = index
  },
  setJoinTab(index){
    state.joinTab = index
  },
  setContactTab(index){
    state.contactTab = index
  }
}

export default {
  state,
  mutations
}

