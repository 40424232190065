<template>
  <div ref="js_tab">
    <div class="my-tabs" :class="{ 'fixed' : tabFixed }">
      <div>
        <a href="javascript:;" v-for="(item,i) in tabs" :key="i"
          :class="{'active': current == i}" @click="tabClick(i)">{{ item.name || item }}</a>
      </div>
    </div>
    <div v-show="tabFixed" class="tabFixed"></div>
  </div>
</template>

<script>
export default {
  name: 'myTabs',
  props:{
    tabs: {
      type: Array,
      require: true,
      default:()=>{
        return []
      }
    },
    index: {
      type: [Number,String],
      default: 0
    }
  },
  data(){
    return {
      current: 0,
      tabFixed: false
    }
  },
  mounted() {
    let t = this
    t.current = t.index
    window.onscroll = function(){
      let obj = t.$refs.js_tab,
        sTop = window.scrollY || window.pageYOffset,
        topArr = [],
        joinus = t.$route.path === '/joinus',
        contact = t.$route.path === '/contact'
      if(joinus || contact){
        for(let i = 0; i < t.tabs.length; i++){
          let id = '#' + (joinus ? 'faith' : 'contact') + i
          let section = document.querySelector(id)
          if(section){
            topArr.push(section.offsetTop - window.screen.availHeight + (obj ? obj.offsetTop : 0))
          }        
        }
        for(var j = 0; j < t.tabs.length; j++){
          if(sTop >= (topArr[j-1] || 0) && sTop < (topArr[j+1] || 999999999)) {
            t.current = j
            break;
          }
        }
      }
      if(obj) {
        let top = obj.offsetTop
        if(sTop > top) t.tabFixed = true
        else t.tabFixed = false  
      }
    }
  },
  watch:{
    index(v){
      let t = this
      t.current = v
      if(t.$route.path === '/about') t.$emit('on-tab', v)
    }
  },
  methods: {
    tabClick(i){
      let t = this
      t.current = i
      if(t.$route.path === '/about') {
        t.$emit('on-tab', i)
      }
      else {
        let section = document.querySelector("#" + (t.$route.path === '/joinus' ? 'faith' : 'contact') + i)
        if(section){
          let st = section.offsetTop - (window.screen.availWidth <= 1200 ? 80 : 0)
          window.scroll(0, st)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .my-tabs{
    position: relative;
    height: 70px;
    border-bottom: 1px solid $color-border;
    &.fixed{
      position: fixed;
      top: 0; right: 0; left: 0; z-index: 99;
      background: #fff;
    }
    >div{
      width: 1200px;
      font-size: 0;
      margin: 0 auto;
    }
    a{
      display: inline-block;
      height: 70px;
      line-height: 68px;
      font-size: 16px;
      color: $color-primary - $color-primary;
      border-bottom: 2px solid transparent;
      @extend %transition;
      &+a{
        margin-left: 35px;
      }
      &:hover{
        color: $color-main;
      }
      &.active{
        border-bottom-color: $color-main;
        color: $color-main;
        font-weight: bold;
      }
    }
  }
  .tabFixed{
    height: 70px;
  }

  @media screen and (max-width:1200px) {
    .my-tabs{
      height: px2rem(35px);
    &.fixed{
      top: px2rem(44px);
    }
      >div{
        width: auto;
        padding: 0 px2rem(15px);
      }
      a{
        height: px2rem(35px);
        line-height: px2rem(34px);
        font-size: px2rem(14px);
        &+a{
          margin-left: px2rem(15px);
        }
        &:hover{
          color: $color-primary - $color-primary;
        }
        &.active{
          color: $color-main!important;
        }
      }
    }
    .tabFixed{
      height: px2rem(35px);
    }
  }
</style>
