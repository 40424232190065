import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    meta: {
      title: '深圳市优讯信息技术有限公司'
    }
  },
  {
    path: '/example',
    name: 'example',
    component: () => import('../views/example/index.vue'),
    meta: {
      title: '成功案例'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service/index.vue'),
    meta: {
      title: '运营服务'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue'),
    meta: {
      title: '关于优讯'
    }
  },
  {
    path: '/cloud',
    name: 'cloud',
    component: () => import('../views/cloud/index.vue'),
    meta: {
      title: '云服务'
    }
  },
  {
    path: '/production',
    name: 'production',
    component: () => import('../views/prod/index.vue'),
    meta: {
      title: '平台产品'
    }
  },
  {
    path: '/joinus',
    name: 'joinus',
    component: () => import('../views/joinus/index.vue'),
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/index.vue'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/open',
    name: 'open',
    component: () => import('../views/open/index.vue'),
    meta: {
      title: '开放生态'
    }
  },
  {
    path: '*',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    meta: {
      title: '深圳市优讯信息技术有限公司'
    },
  }
]

const router = new VueRouter({
  routes,
})

export default router

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  window.scrollTo(0, 0)
  next()
  // if(IEVersion() > 0 && IEVersion() < 10) location.reload()
})

// function IEVersion () {
//   var userAgent = navigator.userAgent 
//   var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1  
//   var isEdge = userAgent.indexOf("Edge") > -1 && !isIE
//   var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1
//   if(isIE) {
//     var reIE = new RegExp("MSIE (\\d+\\.\\d+);")
//     reIE.test(userAgent)
//     var fIEVersion = parseFloat(RegExp["$1"])
//     return fIEVersion
//   }
//   if(isEdge) return 'edge'
//   if(isIE11) return 11         // IE11  
//   return -1                    // 不是ie浏览器
// }
