<template>
  <div class="my-header" @touchmove.prevent>
    <h1 class="logo"><router-link to='/'><img src="@/assets/images/logo.png" alt="优讯信息" /></router-link></h1>
    <div class="menu">
      <a href="javascript:;" class="menu-handle" :class="{'active':menushow}" @click="menushow = !menushow"></a>
      <ul v-show="menushow" @click="menuClick">
        <li v-for="(item, i) in menus" :key="i" @click="gotopage(i)">
          <router-link :to='item.path'>{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myHeader',
  data(){
    return {
      menushow: true,
      menus: [
        {name:'首 页', path: '/'},
        {name:'关于优讯', path: '/about'},
        {name:'平台产品', path: '/production'},
        {name:'运营服务', path: '/service'},
        {name:'开放生态', path: '/open'},
        {name:'云服务', path: '/cloud'},
        {name:'成功案例', path: '/example'},
        {name:'加入我们', path: '/joinus'},
        {name:'联系我们', path: '/contact'}                        
      ]
    }
  },
  mounted(){
    let t = this,
      w = window.screen.width
    if(w <= 1200) t.menushow = false
    window.onresize = function(){
      w = window.screen.width
      if(w <= 1200) t.menushow = false
      else t.menushow = true
    }
  },
  methods: {
    gotopage(i){
      let t = this
      if(t.$lowie){
        setTimeout(()=>{
          let href = location.href
          window.location.href = href.substr(0,href.lastIndexOf('#/')+1) + t.menus[i].path
          location.reload()
        },50)
      }
    },
    menuClick(){
      let t = this
      if(window.screen.availWidth <= 1200) t.menushow = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped  lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .my-header{
    @extend %c;
    width: 1200px;
    margin: 0 auto;
  }
  .logo{
    float: left;
    padding: 20px 0;
    a{
      @extend %db;
    }
    img{
      display: block;
      width: 112px;
      height: 60px;
    }
  }
  .menu{
    float: right;
    ul{
      @extend %c;
    }
    li{
      float: left;
      list-style: none;
      &+li{
        margin-left: 40px;
      }
      a{
        display: block;
        height: 100px;
        line-height: 96px;
        font-size: 16px;
        color: $color-primary;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        @extend %transition;
        &:hover{
          color: $color-main;
        }
        &.router-link-active.router-link-exact-active{
          color: $color-main;
          font-weight: bold;
          border-bottom-color: $color-main;
        }
      }
    }
  }
  .menu-handle{
    @extend %dn;
  }

  @media screen and (max-width:1200px) {
    .my-header{
      position: fixed;
      top: 0; right: 0; left: 0; z-index: 999;
      display: flex;
      align-items: center;
      width: auto;
      height: px2rem(44px);
      padding: 0 px2rem(15px);
      background: #f5f5f5;
      .logo{
        float: none;
        padding: 0;
        img{
          width: auto;
          height: px2rem(30px);
        }
      }
      .menu{
        // position: relative;
        flex: 1;
        height: px2rem(44px);
        &,li{
          float: none;
        }
        ul{
          position: fixed;
          top: px2rem(44px); right: 0; left: 0; bottom: 0;
          background: rgba(0,0,0,0.3);
          // padding: px2rem(15px) 0;
        }
        li{
          float: none;
          background: #fff;
          margin-bottom: -1px;
          &+li{
            margin-left: 0;
          }
          a{
            height: px2rem(40px);
            line-height: px2rem(40px);
            font-size: px2rem(15px);
            border-top: none;
            border-bottom: none;
            text-align: center;
          }
        }

      }
    }
    .menu-handle{
      position: fixed;
      top: 0; right: 0;
      display: block;
      width: px2rem(54px);
      height: px2rem(44px);
      &:before,&:after{
        position: absolute;
        right: px2rem(15px);
        content: '';
        display: block;
        width: px2rem(24px);
        border-bottom: 2px solid $color-primary * 3;
        transition: all 0.3s linear 0.05s;
      }
      &:before{
        top: px2rem(15px);
      }
      &:after{
        top: px2rem(21px);
        height: px2rem(4px);
        border-top: 2px solid $color-primary * 3;
      }
      &.active{
        &:before,&:after{
          top: 50%;
          height: auto;
          border-top: none;
          transform-origin: center center;
        }
        &:before{
          transform: translate(0,-50%) rotate(-45deg);
        }
        &:after{
          transform: translate(0,-50%) rotate(45deg);
        }
      }
    }
  }
</style>
