<template>
  <div class="swiper-container bank-swiper" :class="{'lowie' : lowie}" :id="swiperId">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,i) in bankSwiperArr" :key="i">
        <template v-for="(bank,j) in banks">
          <a class="bank" href="javascript:;" v-if="j>=i*rate&&j<(i+1)*rate" :title="bank.name" :key="j">
            <img :src="bank.logo" />
          </a>
        </template>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div v-if="arrow" class="swiper-button-prev"></div>
    <div v-if="arrow" class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css'
export default {
  name: 'banks',
  props: {
    swiperId: {
      type: String,
      require: true
    },
    arrow: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      lowie: false,
      rate: 20,
      banks: [
        {name:'中国工商银行', logo: require('@/assets/images/banks/b-01.jpg')},
		{name:'中国农业银行', logo: require('@/assets/images/banks/nonghang-2.png')},
		{name:'中国银行', logo: require('@/assets/images/banks/zhonghang.png')},
		{name:'中国建设银行', logo: require('@/assets/images/banks/jianhan.png')},
		{name:'中国邮政储蓄银行', logo: require('@/assets/images/banks/youzheng.png')},
		{name:'中信银行', logo: require('@/assets/images/banks/zhongxin.png')},
		{name:'广发银行', logo: require('@/assets/images/banks/b-03.jpg')},
		{name:'兴业银行', logo: require('@/assets/images/banks/xingye.png')},
		{name:'浦发银行', logo: require('@/assets/images/banks/pufa.png')},
        
        
        {name:'郑州银行', logo: require('@/assets/images/banks/b-04.jpg')},
        {name:'富邦华一银行', logo: require('@/assets/images/banks/b-05.jpg')},
        {name:'兰州银行', logo: require('@/assets/images/banks/b-06.jpg')},
		{name:'深圳农村商业银行', logo: require('@/assets/images/banks/b-11.jpg')},
        {name:'江苏银行', logo: require('@/assets/images/banks/b-07.jpg')},
        {name:'九江银行', logo: require('@/assets/images/banks/b-08.jpg')},
        {name:'中原银行', logo: require('@/assets/images/banks/b-09.jpg')},
        {name:'徽商银行', logo: require('@/assets/images/banks/b-10.jpg')},
        {name:'锦州银行', logo: require('@/assets/images/banks/b-12.jpg')},
        {name:'广州农商银行', logo: require('@/assets/images/banks/b-13.jpg')},
		{name:'齐商银行', logo: require('@/assets/images/banks/b-16.jpg')},
		{name:'泰安银行', logo: require('@/assets/images/banks/b-17.jpg')},
		{name:'济宁银行', logo: require('@/assets/images/banks/b-18.jpg')},
        {name:'阜新银行', logo: require('@/assets/images/banks/b-14.jpg')},
        {name:'甘肃银行', logo: require('@/assets/images/banks/b-15.jpg')},
        {name:'四川天府银行', logo: require('@/assets/images/banks/b-21.jpg')},
        {name:'东营银行', logo: require('@/assets/images/banks/b-19.jpg')},
        {name:'日照银行', logo: require('@/assets/images/banks/b-20.jpg')},
        {name:'唐山银行', logo: require('@/assets/images/banks/b-26.jpg')},
		{name:'长安银行', logo: require('@/assets/images/banks/b-31.jpg')},
		{name:'枣庄银行', logo: require('@/assets/images/banks/b-25.jpg')},
        {name:'厦门国际银行', logo: require('@/assets/images/banks/b-22.jpg')},
        {name:'厦门银行', logo: require('@/assets/images/banks/b-23.jpg')},
        {name:'泉州银行', logo: require('@/assets/images/banks/b-24.jpg')},
        {name:'张家口银行', logo: require('@/assets/images/banks/b-27.jpg')},
		{name:'邢台银行', logo: require('@/assets/images/banks/b-30.jpg')},
        {name:'杭州银行', logo: require('@/assets/images/banks/b-28.jpg')},
		{name:'四川银行', logo: require('@/assets/images/banks/sichuang.png')},
        {name:'浙江泰隆商业银行', logo: require('@/assets/images/banks/b-29.jpg')},
        {name:'齐鲁银行', logo: require('@/assets/images/banks/b-33.jpg')},
        {name:'桂林银行', logo: require('@/assets/images/banks/b-34.jpg')},
        {name:'瑞丰银行', logo: require('@/assets/images/banks/b-35.jpg')},
        {name:'柳州银行', logo: require('@/assets/images/banks/b-36.jpg')},
        {name:'上饶银行', logo: require('@/assets/images/banks/b-37.jpg')},
        {name:'石嘴山银行', logo: require('@/assets/images/banks/b-38.jpg')},
        {name:'重庆三峡银行', logo: require('@/assets/images/banks/b-39.jpg')},
		{name:'吉林银行', logo: require('@/assets/images/banks/b-42.jpg')},
		{name:'青岛农商银行', logo: require('@/assets/images/banks/b-43.jpg')},
		{name:'紫金农商银行', logo: require('@/assets/images/banks/b-44.jpg')},
        {name:'江苏长江商业银行', logo: require('@/assets/images/banks/b-40.jpg')},
        {name:'海南省农村信用社', logo: require('@/assets/images/banks/b-41.jpg')},
        {name:'常熟农村商业银行', logo: require('@/assets/images/banks/b-47.jpg')},
        {name:'张家港农村商业银行', logo: require('@/assets/images/banks/b-48.jpg')},
        {name:'CRCBANK长治农商银行', logo: require('@/assets/images/banks/b-49.jpg')},
        {name:'沧州农商银行', logo: require('@/assets/images/banks/b-45.jpg')},
        {name:'昆山农村商业银行', logo: require('@/assets/images/banks/b-46.jpg')},
		{name:'江西裕民银行', logo: require('@/assets/images/banks/b-52.jpg')},
        {name:'泸州银行', logo: require('@/assets/images/banks/b-51.jpg')},
        {name:'众邦银行', logo: require('@/assets/images/banks/zongbang.png')},
		{name:'亿联银行', logo: require('@/assets/images/banks/yilian.png')},
		
        {name:'中旅银行', logo: require('@/assets/images/banks/b-53.jpg')},
        {name:'洛阳银行', logo: require('@/assets/images/banks/b-54.jpg')},
		{name:'辽宁银行', logo: require('@/assets/images/banks/b-50.jpg')},
		{name:'中信建投证券', logo: require('@/assets/images/banks/b-02.jpg')},
		{name:'攀枝花市商业银行', logo: require('@/assets/images/banks/b-32.jpg')},
      ]
    }
  },
  computed: {
    bankSwiperArr(){
      let t = this
      let w = window.screen.width
      if(w <= 1200) t.rate = 12
      let arr = t.$lowie ? [''] : new Array(Math.ceil(t.banks.length/t.rate))
      return arr
    }
  },
  mounted() {
    let t = this
    if(!t.$lowie){
      let w = window.screen.width
      if(w <= 1200) t.rate = 12
      // window.onresize = function(){
      //   if(window.screen.width <= 1200) t.rate = 15
      //   else t.rate = 20
      // }
      let opt = {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        loop: true,
        speed: 600,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      }
      if(t.arrow){
        opt.prevButton = '.swiper-button-prev'
        opt.nextButton = '.swiper-button-next'
      }
      new Swiper('#' + this.swiperId, opt)
    }
    else{
      t.lowie = true
      t.rate = 1000
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .bank-swiper{
    width: 1250px;
    height: 476px;
    margin: 0 auto;
    &.lowie{
      &,.swiper-slide{
        height: auto;
      }
    }
    .swiper-slide{
      height: 476px;
      padding-top: 15px;
      font-size: 0;
    }
    .bank{
      display: inline-block;
      width: 200px;
      height: 70px;
      overflow: hidden;
      font-size: 0;
      text-align: center;
      border-radius: 10px;
      // @extend %transition;
      // box-shadow: 0 0 15px rgba(255,255,255,0);
      box-shadow: 0 0 15px rgba(0,0,0,0.04);
      margin: 0 25px 30px;
      padding: 19px 0;
      img{
        @extend %transition;
        display: inline-block;
        height: 32px;
      }
      &:hover{
        // box-shadow: 0 0 15px rgba(0,0,0,0.04);
        img{
          transform: scale(1.12);
        }
      }
    }
    .swiper-pagination{
      bottom: 35px;
    }
    .swiper-pagination-bullet{
      width: 12px;
      height: 6px;
      border-radius: 6px;
      background: #000;
      opacity: 0.1;
      &.swiper-pagination-bullet-active{
        width: 18px;
        opacity: 0.3;
      }
    }
    .swiper-button-prev,.swiper-button-next{
      display: none;
      width: 11px;
      height: 21px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 11px 21px;
      opacity: 0.5;
      top: 210px;
      &:hover{
        opacity: 1;
        // box-shadow: 0 0 15px rgba(0,0,0,0.04);
      }
    }
    .swiper-button-prev{
      left: 0;
      background-image: url("~@/assets/images/arrow-left.png");
    }
    .swiper-button-next{
      right: 0;
      background-image: url("~@/assets/images/arrow-right.png");
    }
    &:hover{
      .swiper-button-prev,.swiper-button-next{
        display: block;
      }
    }
  }

  @media screen and (max-width:1200px) {
    .bank-swiper{
      width: 100%;
      height: px2rem(360px);
      .swiper-slide{
        height: px2rem(360px);
        padding-top: px2rem(7.5px);
      }
      .bank{
        width: 44%;
        height: px2rem(36px);
        border-radius: px2rem(5px);
        box-shadow: 0 0 px2rem(7.5px) rgba(0,0,0,0.04);
        padding: px2rem(10px) 0;
        &:nth-of-type(odd){
          margin: 0 2% px2rem(15px) 4%;
        }
        &:nth-of-type(even){
          margin: 0 4% px2rem(15px) 2%;
        }
        img{
          display: inline-block;
          height: px2rem(16px);
        }
        &:hover{
          img{
            transform: scale(1);
          }
        }
      }
      .swiper-pagination{
        bottom: px2rem(15px);
      }
      .swiper-pagination-bullet{
        width: px2rem(6px);
        height: px2rem(2px);
        border-radius: px2rem(2px);
        background: #000;
        opacity: 0.1;
        &.swiper-pagination-bullet-active{
          width: px2rem(10px);
          opacity: 0.3;
        }
      }
      .swiper-button-prev,.swiper-button-next{
        &,&:hover{
          display: none!important;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
</style>
