<template>
  <div class="my-footer">
    <div class="footer-inner">
      <dl v-for="(item,i) in siteMap" :key="i">
        <dt>{{ item.name }}</dt>
        <dd>
          <a href="javascript:;" v-for="(item2,j) in item.child" :key="j" @click="footlink(i,j)">{{ item2.name }}</a>
        </dd>
      </dl>
      <div class="address">
        <div>
          <span class="tel">400-998-3629</span>
          <span class="tips">全国统一服务热线</span>
        </div>
        <p>地址：广东省深圳市福田区国际金融科技城1701-1704<br/>传真：0755-33501096</p>
      </div>
    </div>
    <p>深圳市优讯信息技术有限公司&nbsp;Copyright&nbsp;&copy;&nbsp;2009&nbsp;-&nbsp;2023&nbsp;<a href="http://beian.miit.gov.cn/" rel="noopener" target="_blank"> 粤ICP备11082811号</a></p>
  </div>
</template>

<script>
import store from "@/store"
export default {
  name: 'myHeader',
  data(){
    return {
      siteMap: [
        {
          name: '关于优讯',
          child: [
            {name:'公司介绍', path: '/about'},
            {name:'组织结构', path: '/about'},
            {name:'企业文化', path: '/about'}
          ]
        },
        {
          name: '加入我们',
          child: [
            {name:'人才理念', path: '/joinus'},
            {name:'职位招聘', path: '/joinus'}
          ]
        },
        {
          name: '服务支持',
          child: [
            {name:'客户服务', path: '/contact'},
            {name:'联系我们', path: '/contact'}
          ]
        }
      ]
    }
  },
  methods: {
    footlink(i,j){
      let t = this,
        p = t.siteMap[i].child[j].path
      switch(i){
        case(0):store.mutations.setAboutTab(j);break;
        case(1):store.mutations.setJoinTab(j);break;
        case(2):store.mutations.setContactTab(j);break;
      }
      if(t.$route.path !== p) {
        t.$router.push({
          path: p
        })
      }
      setTimeout(function(){
        if(i === 0) return window.scroll(0,0)
        let screenJudge =  window.screen.availWidth <= 1200 ? 80 : 0
        if(i === 1) return window.scroll(0,document.querySelector("#faith" + j).offsetTop - screenJudge)
        if(i === 2) return window.scroll(0,document.querySelector("#contact" + j).offsetTop - screenJudge)
      },50)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped  lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  @import '@/assets/scss/_mixin.scss';
  .my-footer{
    background: #37405f;
    >p{
      @include lineHeight(56px);
      text-align: center;
      font-size: 14px;
      color: rgba(255,255,255,0.5);
      border-top: 1px solid rgba(255,255,255,0.1);
      a{
        color: rgba(255,255,255,0.5);
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .footer-inner{
    @extend %c;
    width: 1200px;
    margin: 0 auto;
    padding: 45px 0 25px;
    dl{
      float: left;
      font-size: 0;
      &+dl{
        margin-left: 125px;
      }
      a{
        display: block;
        @include lineHeight(26px);
        font-size: 16px;
        color: rgba(255,255,255,0.5);
        @extend %transition;
        &:hover{
          color: #fff;
          
        }
      }
    }
    dt{
      @include lineHeight(28px);
      font-size: 18px;
      color: #fff;
      margin-bottom: 20px;
    }
  }
  .address{
    float: right;
    font-size: 13px;
    color: rgba(255,255,255,0.5);
    line-height: 24px;
    span{
      display: block;
      @include lineHeight(28px);
    }
    .tel{
      color: #fff;
      font-size: 24px;
    }
    .tips{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:1200px) {
    .my-footer{
      >p{
        height: auto;
        line-height: px2rem(20px);
        font-size: px2rem(12px);
        padding: px2rem(15px) 0;
        a{
          display: block;
        }
      }
    }
    .footer-inner{
      width: auto;
      padding: px2rem(25px) 0 px2rem(15px);
      dl{
        width: 33.3%;
        text-align: center;
        margin-bottom: px2rem(15px);
        &+dl{
          margin-left: 0;
        }
        a{
          height: px2rem(24px);
          line-height: px2rem(24px);
          font-size: px2rem(12px);
        }
      }
      dt{
        height: px2rem(20px);
        line-height: px2rem(20px);
        font-size: px2rem(15px);
        margin-bottom: px2rem(10px);
      }
    }
    .address{
      float: none;
      clear: both;
      font-size: px2rem(13px);
      line-height: px2rem(20px);
      text-align: center;
      padding: px2rem(15px) px2rem(15px) 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      span{
        height: px2rem(28px);
        line-height: 1;
      }
      .tel{
        font-size: px2rem(18px);
      }
      .tips{
        font-size: px2rem(12px);
        margin-bottom: px2rem(5px);
      }
    }
  }
</style>
