<template>
  <ul class="prud-list">
    <li v-for="(item,i) in prod" :key="i">
      <img :src="item.icon" />
      <dl>
        <dt>{{ item.name }}</dt>
        <dd :class="{'double' : item.double }">{{ item.intro }}</dd>
      </dl>
    </li>
  </ul>
</template>

<script>
import prod from '@/assets/js/production.js';
export default {
  name: 'prodList',
  props: {
    
  },
  data(){
    return {
      prod
    }
  },
  computed: {
    
  },
  mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .prud-list{
    @extend %c;
    width: 1260px;
    margin: 0 auto;
    li{
      @extend %c;
      display: flex;
      align-items: center;
      display: table\9; // IE9
      float: left;
      width: 360px;
      height: 90px;
      margin: 0 30px 30px;
      background: #fff;
      border-radius: 10px;
      padding: 0 20px;
      cursor: pointer;
      // @extend %transition;
      box-shadow: 0 0 15px rgba(0,0,0,0.04);
      // box-shadow: 0 0 15px rgba(255,255,255,0);
      &:hover{
        // box-shadow: 0 0 15px rgba(0,0,0,0.04);
        // padding: 0 20px;
        // dl{
        //   width: 250px;
        // }
        dd{
          padding-top: 10px;
          height: 28px;
          opacity: 1;
          color: #bababa;
          &.double{
            height: 46px;
          }
        }
      }
    }
    img{
      display: block;
      width: 50px;
      height: 50px;
      display: table-cell\9;
      vertical-align: middle\9;
      margin-right: 20px;
      margin-top: 20px\9;
    }
    dl{
      // @extend %transition;
      flex: 1;
      width: 250px;
      display: table-cell\9;
      vertical-align: middle\9;
    }
    dt{
      font-size: 18px;
      line-height: 24px;
    }
    dd{
      @extend %transition;
      font-size: 14px;
      color: #fff;
      line-height: 18px;
      text-align: justify;
      padding-top: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }

  @media screen and (max-width:1200px) {
    .prud-list{
      width: 100%;
      padding: 0 4%;
      li{
        display: block;
        width: 47.8%;
        height: px2rem(160px);
        border-radius: px2rem(5px);
        padding: px2rem(20px) px2rem(15px) 0;
        margin: 0 0 px2rem(15px);
        box-shadow: 0 0 px2rem(7.5px) rgba(0,0,0,0.04);
        &:nth-of-type(odd){
          margin-right: 4.4%;
        }
        &:hover{
          dd{
            padding-top: 0;
            height: auto;
            &.double{
              height: auto;
            }
          }
        }
      }
      img{
        width: px2rem(50px);
        height: px2rem(50px);
        margin: 0 auto px2rem(10px);
      }
      dl{
        width: auto;
        text-align: center;
      }
      dt{
        font-size: px2rem(14px);
        line-height: px2rem(20px);
        margin-bottom: px2rem(10px);
      }
      dd{
        font-size: px2rem(12px);
        line-height: px2rem(16px);
        height: auto;
        opacity: 1;
        color: #bababa;
        text-align: center;
      }
    }
  }
</style>
