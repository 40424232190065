<template>
  <div class="top-banner" :style="'background-image:url(' + path + ');'"></div>
</template>

<script>
export default {
  name: 'banner',
  props: {
    path: {
      type: String,
      require: true
    }
  },
  data(){
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped  lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .top-banner{
    height: 210px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  @media screen and (max-width:1200px) {
    .top-banner{
      height: px2rem(100px);
    }
  }
</style>
