<template>
  <div class="side-code">
    <img src="~@/assets/images/code.png" />
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'sideCode',
  props: {
    
  },
  data(){
    return {
      // codeShow: false
    }
  },
  computed: {
    
  },
  mounted() {
    // let t = this
    // window.onscroll = function(){
    //   if((window.scrollY || window.pageYOffset) > 400) t.codeShow = true
    //   else t.codeShow = false
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .side-code{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 630px;
    @extend %transition;
    // opacity: 0;
    width: 120px;
    cursor: pointer;
    transform: translate(0,-50%);
    &:hover{
      margin-left: 550px;
    }
    img{
      display: block;
      width: 96px;
      margin: 0 auto 12px;
    }
    p{
      text-align: center;
      font-size: 14px;
      color: $color-primary * 2;
      line-height: 18px;
    }
  }

  @media screen and (max-width:1200px) {
    .side-code{
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      width: auto;
      transform: translate(0,0);
      padding: px2rem(30px) 0 px2rem(50px);
      &:hover{
        margin-left: 0;
      }
      img{
        width: px2rem(100px);
        margin: 0 auto px2rem(10px);
      }
      p{
        font-size: px2rem(12px);
        line-height: px2rem(18px);
      }
    }
  }
</style>
