<template>
  <div class="section-header">
    <h2>{{ title }}</h2>
    <p v-if="desc" class="description">{{ desc }}</p>
    <slot></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'mySection',
  props:{
    title: {
      type: [String, Number],
      require: true
    },
    desc: {
      type: [String, Number]
    }
  },
  data(){
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .section-header{
    >h2{
      position: relative;
      text-align: center;
      line-height: 1;
      font-size: 42px;
      font-weight: normal;
      padding: 80px 0;
      &:after{
        position: absolute;
        top: 146px;
        left: 50%;
        content: '';
        display: block;
        width: 50px;
        height: 6px;
        background: $color-main;
        margin-left: -25px;
      }
    }
  }
  .description{
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px;
  }

  @media screen and (max-width:1200px) {
    .section-header{
      >h2{
        font-size: px2rem(20px);
        padding: px2rem(40px) 0;
        &:after{
          top: px2rem(73px);
          width: px2rem(25px);
          height: px2rem(3px);
          margin-left: px2rem(-12.5px);
        }
      }
    }
    .description{
      // height: px2rem(22px);
      line-height: px2rem(22px);
      font-size: px2rem(13px);
      margin-bottom: px2rem(30px);
      padding: 0 px2rem(15px);
      text-align: left;
    }
  }
</style>
